/**
 * Americor Phone Helper
 *
 * Get and set phone numbers by vendor ID
 */
"use strict";

export default class AmericorPhone
{
  /**
   * API URL
   * 
   * @param string api_url
   */
  static api_url;

  /**
   * Phone class
   * 
   * @var string
   */
  static phone_class = '.vid-phone';

  /**
   * Constructor
   * 
   * @param string toolkit_base_url
   */
  constructor( toolkit_base_url )
  {
    AmericorPhone.api_url = toolkit_base_url + '/api';

    this.set_vendor();
    this.add_listeners();
  }

  /**
   * Set vendor ID from URL
   * 
   * @returns void
   */
  async set_vendor()
  {
    const params = new URLSearchParams(window.location.search);
    const urlParams = new URLSearchParams(
      Array.from(params, ([key, value]) => [key.toLowerCase(), value])
    );

    // If no vendor ID in URL, return
    if ( ! urlParams.has('vid') ) return false;

    const url     = AmericorPhone.api_url + '/vendor';
    const url_vid = parseInt( urlParams.get('vid') );

    // Check if vendor ID is already set in sessionStorage, or if URL vid is different
    if ( ! sessionStorage.getItem('vendor_id') || sessionStorage.getItem('vendor_id') != url_vid )
    {
      // Set sessionStorage vendor_id
      sessionStorage.setItem('vendor_id', url_vid);

      const response = await fetch( url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'vendor',
          content: {
            vendorId: url_vid
          },
          params: {}
        })
        })
        .then( (response) => response.json() )
        .catch( (error) => {
          console.log(error);
        });

      // Vendor ID not found
      if ( response.status && response.status == 404 )
      {
        document.dispatchEvent( new CustomEvent('vid:notFound', {
          bubbles: true,
          detail: response
        } ) );
      }
      
      // Set Vendor ID
      else
      {
        if ( response.contactPhone != null )
        {
          sessionStorage.setItem('vendor_phone', response.contactPhone);
        }

        // Dispatch event
        document.dispatchEvent( new CustomEvent('vid:ready', {
          bubbles: true,
          detail: response
        } ) );
      }
    }
  }

  /**
   * Add event listeners
   *
   * @return void
   */
  add_listeners()
  {
    document.addEventListener('DOMContentLoaded', (e) => {
      this.mask_phone();
      this.get_vid_phone();
    });

    document.addEventListener('vid:ready', (e) => {
      this.get_vid_phone();
    });
  }

  /**
   * Add phone masking
   * 
   */
  mask_phone()
  {
    const phone = document.querySelector('input[type=tel], input[name=phone]');
    
    phone?.addEventListener('input', ( e ) => {
      var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    });
  }

  /**
   * Get and set phone numbers
   *
   */
  get_vid_phone()
  {
    const phones = document.querySelectorAll( AmericorPhone.phone_class );

    if ( phones.length == 0 ) return;
    if ( ! sessionStorage.getItem('vendor_id') ) return

    const vendor_phone = sessionStorage.getItem('vendor_phone');
    
    phones.forEach( ( phone ) => {
      const num = this.format_phone_number(vendor_phone);

      if ( num == null )
        return;

      phone.href = 'tel:' + num['href'];

      if ( ! phone.classList.contains('link-only') )
        phone.innerHTML = num['text'];
    });
  }

  /**
   * Format phone number
   * 
   * @param string phone_string
   * @return string
   */
  format_phone_number( phone_string )
  {
    var cleaned = ('' + phone_string).replace(/\D/g, '');
    var match   = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if ( match )
    {
      var intlCode = (match[1] ? '+1 ' : '');
      var number   = new Array;

      number['href'] = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      number['text'] = ['(', match[2], ') ', match[3], '-', match[4]].join('');

      return number;
    }

    return null;
  }
}