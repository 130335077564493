/**
 *
 *  Smarty Autocomplete
 *   - https://www.smarty.com/docs/cloud/authentication
 *   - https://www.smarty.com/docs/cloud/international-address-autocomplete-api
 *
 *   - Takes an input from a client then calls the smarty api with that input
 *    * Dispays that response in a list view under the input
 *    * Clicking a element in the display populates all other listed inputs with the data from that element
 *
 */
"use strict";

export default class SmartyAutocomplete 
{  
  /**
   * Wrapper
   * 
   * @var object
   */
  static forms = document.querySelectorAll('form.americor-form');

  /**
   * key 
   *  - Smarty Key
   * 
   * @var string 
   */
  static key = '32628763833673864';

  /**
   * max_results 
   *  - Smarty results limit
   * 
   * @var int 
   */
  static max_results = 5;

  /**
   * Constructor
   *
   * @returns
   */
  constructor( form_fields )
  {
    this.form_fields = form_fields;

    this.init();
  }

  /**
   * Init fields inside each form and gen a unique smarty result popup for each
   *
   */
  init() {
    const el    = this;
    const forms = SmartyAutocomplete.forms;

    if ( ! forms ) return;

    forms.forEach(( form ) => {
      // If no address field, return
      const address = form.querySelector( el.form_fields.address );

      if ( ! address ) return;

      const street  = form.querySelector( el.form_fields.street );
      const city    = form.querySelector( el.form_fields.city );
      const state   = form.querySelector( el.form_fields.state );
      const zip     = form.querySelector( el.form_fields.zip );

      const elements = {
        'addr': address,
        'street': street,
        'city': city,
        'state': state,
        'zip': zip
      }

      this.attach_autocomplete(address);
      this.add_listeners(elements);
    });
  }

  /**
   * Add listeners to address
   *
   * @var elements = object
   */
  add_listeners(elements) {
    elements['addr'].addEventListener("input", () => {
      this.make_smarty_request(elements);
    });
  }


  /**
   * Attach auto complete menu to address parent
   *
   * @var elements = object
   */
  attach_autocomplete(address) {
    const parent = address.parentElement;
    parent.style.position = 'relative'

    const container = document.createElement('ul');
    container.classList.add('smarty-results', 'hide');

    document.addEventListener('click', (event) => {
      if (!container.contains(event.target)) this._hide_container(container);
    });

    parent.appendChild(container);
  }


  /**
   * Show results of make_smarty_request()
   *
   * @argument elements = object
   * @argument results = array
   */
  show_results(elements, results) {
    const container = this._get_container(elements);
    container.classList.add('results');
    container.innerHTML = '';

    const formattedSuggestions = results.map((result) => {
      const liElement = document.createElement("li");
      const {
        street_line,
        city,
        state,
        zipcode,
        secondary,
      } = result;

      liElement.innerText = `${street_line} ${secondary} ${city} ${state} ${zipcode}`;

      liElement.addEventListener("click", async () => {
        this._hide_container(container);

        let street = street_line;
        if (secondary) street += ` ${secondary}`;

        const click_result = {
          'addr': `${street_line} ${secondary} ${city} ${state} ${zipcode}`,
          'street': street,
          'city': city,
          'state': state,
          'zip': zipcode
        }

        this.propagate_result(elements, click_result);
      });

      return liElement;
    });

    container.append(...formattedSuggestions);
  }

  /**
   * Added the result of element click event to dom elements
   *
   * @argument elements = object
   * @argument results = array
   */
  propagate_result(elements, results) {
    if ( elements['addr'] ) elements['addr'].value = results['addr'];
    if ( elements['street'] ) elements['street'].value = results['street'];
    if ( elements['city'] ) elements['city'].value = results['city'];
    if ( elements['state'] ) elements['state'].value = results['state'];
    if ( elements['zip'] ) elements['zip'].value = results['zip'];
  }

  /**
   * Helper function to get container
   *
   * @argument elements = object
   *
   * @returns HTMLElement
   */
  _get_container(elements) {
    return elements['addr'].parentElement.getElementsByTagName('ul')[0];
  }

  /**
   * Helper function to hide container
   *
   * @argument container = HTMLElement 
   *
   * @returns HTMLElement
   */
  _hide_container(container) {
    container.classList.remove("results");
    container.innerHTML = '';
  }
  
  /**
   * Make Smarty request
   *
   */
  async make_smarty_request(elements) {
    if (elements['addr'].value == '') {
      this._hide_container(this._get_container(elements));
      return;
    }

    const params = new URLSearchParams({
      key: SmartyAutocomplete.key,
      search: elements['addr'].value,
      source: "postal",
      max_results: SmartyAutocomplete.max_results,
    });

    const request = await fetch(
      `https://us-autocomplete-pro.api.smarty.com/lookup?${params}`
    );

    const data = await request.json();

    if (data?.suggestions?.length > 0) this.show_results(elements, data.suggestions);
  }
}
