/**
 * Main app
 *
 */
"use strict";

import AmericorForm from './form.js';
import AmericorPhone from './phone.js';
import CallScheduler from "./call-scheduler.js"
import Cookies from "js-cookie";

class Toolkit {
  /**
   * Base URL
   * 
   * @param string base_url
   */
  static base_url = new URL(document.currentScript.src).origin;
  static form;

  /**
   * Constructor
   * 
   */
  constructor()
  {
    const phone         = new AmericorPhone( Toolkit.base_url );
    const callScheduler = new CallScheduler();
  }

  /**
   * Initialize form
   * 
   * @param object config
   */
  init( config = {} )
  {
    const visitor_uuid = this.getVisitorUUID();

    Toolkit.form = new AmericorForm( Toolkit.base_url, {...config, ...{ visitor_uuid: visitor_uuid }} );
  }

  /**
   * Get visitor UUID from PostHog cookie
   * 
   * @return string
   */
  getVisitorUUID()
  {
    let cookieData = Cookies.get('_ph_bootstrapData');

    if ( ! cookieData ) return;

    return JSON.parse(cookieData)?.distinctID || self.crypto.randomUUID();
  }

  /**
   * Resume paused strategy
   * 
   * @returns response
   */
  async resume()
  {
    return await Toolkit.form.resume();
  }
}

window.toolkit = new Toolkit();
