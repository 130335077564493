/**
 * UTM Helper Component
 *
 */
"use strict";

export default class UTM {
  static parameters = [
    'adid',
    'cid',
    'd',
    'ext',
    'gad_source',
    'gclid',
    'isTesting',
    'kw',
    'kwid',
    'mt',
    'nw',
    'pmt',
    'src',
    'subId1',
    'subId2',
    'subId3',
    'subId4',
    'testId',
    'tgt',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
  ];

  constructor() {
    this.parameters = {};

    this.init();
  }

  /**
   * Initialize UTM parameters
   *
   */
  init() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    for ( const key of urlParams.keys() )
    {
      let i = UTM.parameters.findIndex( item => key.toLowerCase() === item.toLowerCase() );

      if ( i > -1 )
      {
        this.parameters[UTM.parameters[i]] = urlParams.get(key);
        
        if ( UTM.parameters[i].includes('subId') )
        {
          sessionStorage.setItem(UTM.parameters[i], urlParams.get(key));
        }
      }
    }
  }

  /**
   * Return UTM parameters
   *
   * @returns object
   */
  get_parameters() {
    return this.parameters;
  }
}
