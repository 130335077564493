/**
 * UTM Helper Component
 *
 */
"use strict";

export default class TestKit {
  
  /**
   * Is Testing Active
   * 
   * @var boolean
   */
  static isActive = false;

  constructor() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    
    TestKit.isActive = ( urlParams.get('isTesting') && urlParams.get('isTesting') == 112233 ) ? true : false;
    
    this.init();
  }

  /**
   * 
   * @returns boolean
   */
  is_active() {
    return TestKit.isActive;
  }

  /**
   * Initialize testing panel
   *
   */
  init() {
    if ( TestKit.isActive == false ) return;

    let panel = document.createElement('div');
        panel.id = 'testingPanel';
        panel.style = 'position: fixed; bottom: 0; left: 0; width: 100%; background: #000; color: #fff; font-size: 14px; padding: 15px 10px; z-index: 10000';

    let selectEl = document.createElement('select');
        selectEl.id = 'testSelections';
        selectEl.style = 'display: block; color: #000; outline: none; margin: 25px 0 0 10px; padding: 0 5px; border-radius: 5px;';
        selectEl.onchange = function() {
          document.querySelector('textarea[name="testingParameters"]').value = this.value;
        }

    let options = {
      '' : 'Select Testing Parameters',
      '{ "needsSpinwheel": true }': 'Needs Spinwheel',
      '{ "quote": true, "debtCreditReport": 0 }': 'Has quote, debtCreditReport: 0',
      '{ "quote": true, "debtCreditReport": 50000 }': 'Has quote, debtCreditReport: 50000',
      '{ "quote": false, "debtCreditReport": 0 }': 'No quote, debtCreditReport: 0',
      '{ "quote": false, "debtCreditReport": 50000 }': 'No quote, debtCreditReport: 50000',
      '{ "redirectUrl": "https://client.americor.com/online-enrollment" }': 'Go to Online Enrollment',
      '{ "partnerId": 1 }': 'Partner ID: 1',
      '{ "callSchedulerLink": "https://www.google.com/" }': 'Call Scheduler Link to Google',
    }

    for ( let key in options ) {
      let option = document.createElement('option');
          option.value = key;
          option.text = options[key];
          selectEl.appendChild(option);
    }

    let input = document.createElement('textarea');
        input.rows = 5;
        input.style = 'display: block; width: calc(100% - 20px); height: 100px; background: #000; color: #fff; font-size: 14px; border: 1px solid #999; outline: none; padding: 10px 10px 0; margin: 15px 0 0 10px;';
        input.name = 'testingParameters';
        input.placeholder = '# TESTING MODE - Requests do not submit to API. Add testing parameters as a JSON object. Open up browser console to see responses. \r\n\r\nExample: { "debtCreditReport": 5000 }';

    let button = document.createElement('a');
        button.text = 'Toggle Test Panel';
        button.style = 'position: absolute; top: 5px; left: 20px; cursor: pointer;'
        button.onclick = function() {
          input.style.display = ( input.style.display == 'block' ) ? 'none' : 'block';
          selectEl.style.display = ( selectEl.style.display == 'block' ) ? 'none' : 'block';
        }

    panel.appendChild(selectEl);
    panel.appendChild(input);
    panel.appendChild(button);

    document.body.style.paddingBottom = '230px';
    document.body.appendChild(panel);
  }

  /**
   * Get testing parameters
   *
   * @return mixed
   */
  getParameters() {
    if ( TestKit.isActive == false ) return;

    return document.querySelector('textarea[name="testingParameters"]').value;
  }
}
